<template>
  <section id="dashboard-ecommerce">
    <div class="sec1 d-flex justify-content-between pb-1">
      <h1 class="text-black">Help{{ search}}</h1>
      <!-- <ul>
    <li v-for="(item) in items" :key="item.name">
      {{ item.first_name }}
      <b-button size="sm" v-b-modal="'showmodal'" user="'item'" @click="sendInfo(item)">
        Saluta {{item.first_name}}
      </b-button>
    </li>
  </ul>
  <b-modal id="showmodal">
    Hello {{selectedUser.first_name}} {{selectedUser.last_name}} !
  </b-modal> -->
    </div>
    <div v-if="isLoading" class="loading" >
     <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
     </div>
    <div class="d-flex justify-content-center align-items-center" v-else-if="helpData.length == 0">
      <img style="width:13cm; height:13cm" :src="host+'/NoDataFound.svg'" />
    </div>
    <div v-else class="drop-zone">
      <draggable
        v-model="helpData"
        handle=".draggable-task-handle"
        tag="ul"
        class="todo-task-list media-list draggable-task-handle d-inline"
      >
        <b-card
          no-body
          class="card-revenue-budget dash-total-card mb-2 drag-el"
          v-for="(item, index) in helpData"
          @dblclick="sendInfo(item)"
          :key="index"
        >
          <b-row class="p-1">
            <!-- <b-col md="4">
              <div class="d-flex align-items-center">
                <div>
                  <b-form-checkbox
                    v-if="popoverShow"
                    v-model="isRTL"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    inline
                    @click="onPopovcerOpen()"
                  />
                </div>
                <div class="ml-1 mr-2">
                  <img src="../../../assets/images/client/photo.png" />
                </div>
                <div>
                  <div class="font-weight-bolder text-black mb-half">
                    {{ item.name }}
                  </div>
                  <div>www.noon.com</div>
                </div>
              </div>
            </b-col> -->
            <b-col md="1" class="d-flex align-items-center">
              <div class="ml-1">
                <div class="mb-half">#</div>
                <div class="font-weight-bolder text-black">{{ paginationStart + index + 1  }}</div>
              </div>
            </b-col>
            <b-col md="2" class="d-flex align-items-center">
              <div class="">
                <div class="mb-half">Name</div>
                <div class=" text-black mb-half">
                    {{ item.name }}
                  </div>
              </div>
            </b-col>
            <b-col md="3" class="d-flex align-items-center">
              <div class="ml-1">
                <div class="mb-half">Email</div>
                <div class=" text-black">{{ item.email }}</div>
              </div>
            </b-col>
            <b-col md="3" class="d-flex align-items-center text-truncate">
              <div class="ml-1">
                <div class="mb-half">Description</div>
                <!-- <div class="font-weight-bolder text-black ">I want to make big deal can you contact me</div> -->
                <span class="d-inline-block text-truncate text-black" style="max-width: 240px;">{{ item.description }}</span>
              </div>
            </b-col>
            <!-- <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Coupon Code</div>
                <div class="font-weight-bolder text-black">
                  <span class="font-weight-bolder" style="color: #3f8cff"
                    >AE32425</span
                  >
                </div>
              </div>
            </b-col> -->
            <b-col md="1" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Phone</div>
                <div class="text-black">{{ item.whatsapp_number }}</div>
              </div>
            </b-col>
            <b-col
              md="2"
              class="
                d-flex
                align-items-center
                justify-content-center
                text-black
              "
            >
              <div
                style="color:#000; padding: 8px; border-radius: 16px"
              >
                <!-- <feather-icon icon="MoreVerticalIcon" size="25" /> -->
                <!-- <b-button size="sm" v-b-modal="'showmodal'" user="'item'" @click="sendInfo(item)">
        Show
      </b-button> -->
      <button type="button" class="btn btn-primary" style="border-radius: 16px; padding: 8px 20px;" v-b-modal="'showmodal'" @click="sendInfo(item)"> View</button>
              </div>
            
            </b-col>
          </b-row>
        </b-card>
        <b-modal id="showmodal" ref="showmodal">
          <template #modal-header>
            <div style="width:80%;padding: 10px 20px;" class="form-row">
              <div class="form-group col-md-12">
                <h2>Help </h2>
                <!-- <h2>Client {{ showItem.id }}</h2> -->
              </div>
            </div>
            <b-button @click="$refs.showmodal.hide()" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          <b-row class="p-1">
            <b-col md="3">
              <div class="d-flex align-items-center">
                <div>
                <div class="mb-half">Name</div>
                  <div class="font-weight-bolder text-black mb-half">
                    {{ selectedUser.name ? selectedUser.name : '(not set)'  }}
                  </div>
                </div>
              </div>
            </b-col>
           
            <b-col md="3" class="d-flex align-items-center">
                <div>
                  <div class="mb-half">Email</div>
                  <div class="font-weight-bolder text-black">{{selectedUser.email}}</div>
                </div>
              </b-col>
              
              <b-col md="3" class="d-flex align-items-center">
                <div>
                  <div class="mb-half">Number</div>
                  <div class="font-weight-bolder text-black">{{ selectedUser.country_code + selectedUser.whatsapp_number }}</div>
                </div>
              </b-col>
              
              <b-col md="3" class="d-flex align-items-center">
                <div>
                  <div class="mb-half">	Description</div>
                  <div class="font-weight-bolder text-black">{{selectedUser.description}}</div>
                </div>
              </b-col>

              <b-col md="3" class="d-flex align-items-center">
                <div>
                  <div class="mb-half">	Date/Time</div>
                  <div class="font-weight-bolder text-black">{{selectedUser.created_at}}</div>
                </div>
              </b-col>
            </b-row>
          <div class="modal-footer1">
            <button type="button" class="btn btn-default mr-1" style="border: 1px solid black" @click="$refs.showmodal.hide()">
              Close
            </button>
            </div>
  </b-modal>
      </draggable>
      <div class="no-results" :class="{ show: !helpData.length }"></div>
    </div>
    
    <button v-if="!isLoading && helpData.length" type="button" class="w-auto  btn slide-btn">
      <div class="d-flex justify-content-around align-items-center">
       
        <p class="text-black" style="margin: 0">{{ paginationStart + 1 }}-{{ paginationEnd > paginationLength ?
          paginationLength : paginationEnd }} of {{ paginationLength }}</p>
        <feather-icon icon="ArrowLeftIcon" @click="paginationHandelClick('back')" size="18" />
        <input v-model="pageNo" class="form-control paginationInput" :style="'width:' + ((pageNo.toString().length*10)) + 'px'" @input="pagechange" type="number"/>
        <feather-icon icon="ArrowRightIcon" @click="paginationHandelClick('next')" size="18" />
      </div>
    </button>
  </section>
  
</template>

<script>
import axios from 'axios';
import draggable from "vuedraggable";
// import axios from 'axios';
export default {
  components: {
    draggable,
  },
  data() {
    return {
      data: {
  
      },
      
      host:window.location.origin,
      supportInquiries: [
        { name: "salha" },
        { name: "Azhar Iqbal" },
        { name: "Salman" },
        { name: "hamad" },
        { name: "Forkan" },
        { name: "Adnan" },
      ],
      popoverShow: false,
      isLoading:false,
      paginationStart:0,
      pageNo:1,
      paginationEnd:10,
      paginationLength:0,
      popoverShow1: false,
      inlineRadio: "Coupon",
      advetiserRadio: "Itcan",
      value: "1",
    selectedUser: '',
    helpData: [],
    baseURL: process.env.VUE_APP_BASE_URL,
    };
  },
  created() {},
  computed:{
      search() {
          // let data = []
          let search = this.$store.state.searchData.replace(/\s/g,'').toLowerCase()
          // data = this.helpData1.filter(item => (item.name_en.replace(/\s/g,'').toLowerCase().includes(search) 
          //                                              || item.website.replace(/\s/g,'').toLowerCase().includes(search) 
          //                                              || item.sequence.toString().replace(/\s/g,'').toLowerCase().includes(search)
          //                                              || (item.categories[0] ? item.categories[0].name : '').replace(/\s/g,'').toLowerCase().includes(search) 
          //                                              || (item.is_active == 1 ? 'Active' : 'Inactive').replace(/\s/g,'').toLowerCase().includes(search)
          //                                              ))
            this.getSearch(search);
          // this.$store.state.searchData; 
        },
    },
  methods: {
    
    getSearch: _.debounce(function (search) {
      this.pageNo = 1
      this.paginationStart = 0
      this.paginationEnd = 10
      // if(search){
      this.isLoading = true
      this.$http.post(`${this.baseURL}/help/search`, { search: search, pageno: this.pageNo},
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          console.log(res, 'res')
          this.helpData = res.data.data
          this.paginationLength = res.data.totaldata
          this.helpData1 = res.data.data
          this.isLoading = false
        })
      // }
    }, 1000),
    // onPopovcerOpen() {
    //   this.popoverShow = !this.popoverShow;
    // },
    // onPopovcerOpen1() {
    //   this.popoverShow1 = !this.popoverShow1;
    // },
    sendInfo(item) {
      // console.log("hello",item)
      this.$refs.showmodal.show()

        this.selectedUser = item;

    },
    
    pagechange(){
      if(this.pageNo>(this.paginationLength/10)){
        this.pageNo = parseInt(this.paginationLength/10)
      }
      if(this.pageNo == 0){
        this.pageNo = 1
      }
        this.paginationStart = (this.pageNo*10)-10
        this.paginationEnd = (this.pageNo*10)
      let search = this.$store.state.searchData
          if (search) {
            this.searchPaginationHandelClick(search)
          } else {
            this.normalPaginationHandelClick(search)
          }
    },
    searchPaginationHandelClick: _.debounce(function (search) {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/help/search`, { search: search, pageno: this.pageNo}).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.helpData = res.data.data
        // this.pageNo=res.data.page
            this.selectAllLabel = 'Select All'
              this.checkBoxIds = {}
        this.paginationLength = res.data.totaldata
        this.helpData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    normalPaginationHandelClick: _.debounce(function () {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/help/index`, { pageno: this.pageNo}).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.helpData = res.data.data
        // this.pageNo=res.data.page
        this.paginationLength = res.data.totaldata
            this.selectAllLabel = 'Select All'
              this.checkBoxIds = {}
        this.helpData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    paginationHandelClick(value) {
      let search = this.$store.state.searchData
      if (value == 'back') {
        if (this.paginationStart > 1) {
          this.pageNo--
          this.paginationStart = this.paginationStart - 10
          this.paginationEnd = this.paginationEnd - 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
      else if (value == 'next') {
        if (this.paginationEnd < this.paginationLength) {
          this.pageNo++
          this.paginationStart = this.paginationStart + 10
          this.paginationEnd = this.paginationEnd + 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
    },
    getUsers() {
			this.isLoading = true
      this.$http.post( `${this.baseURL}/help/index`,{pageno:1}).then((res)=>{
            console.log(res.data.data,"res-=-=")
            this.helpData = res.data.data
            this.paginationLength = res.data.totaldata
            this.helpData1 = res.data.data
            this.isLoading = false
          })
		}
  },
  mounted() {
		this.getUsers();
	}
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
// .draggable-task-handle {
//     transform: translateY(-50%);
//     visibility:visible;
//     cursor: move;

//     .todo-task-list .todo-item:hover & {
//       visibility: hidden;
//     }
// }

.custom-radio {
  border: 1px solid #d8e0f0;
  border-radius: 10px;
  color: #000 !important;
}
.status-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  border: 1px solid #0ac947;
  border-radius: 4px;
}
.mb-half {
  margin-bottom: 5px;
}
.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}
.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15 px;
  margin: 0;
  width: 200px;
  float: right;
}
.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}
.bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 140px !important;
  max-width: 350px !important;
}
.bs-popover-left .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}
.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}
.popover-body {
  border: none !important;
}
.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}
.vs-select__options__content {
  background-color: #ffffff !important;
}
.pop-btn {
  padding: 0 !important;
}
.modal-dialog {
  
}
.modal-body {
   padding: 0px 40px 40px 40px !important
}
.form-control {
  border-radius: 14px;
}
.modal-content {
  border-radius: 24px;
}
.modal-header {
  border-radius: 24px;
}
textarea.form-control {
  height: 150px !important;
}
.modal-footer {
  display: none;
}
.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}
table {
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
// td:nth-child(odd) {
//   font-weight: 900;
// }
</style>
